import i18n from "@/core/plugins/vue-i18n";
import UserService from "@/core/services/users.service";

async function updateUser(id, user) {
  try {
    const res = await UserService.editUser(id, user);
    return res;
  } catch (err) {
    if (err?.response?.status === 400) {
      throw err.response.data;
    }

    throw err?.response?.data?.detail ?? i18n.t("FAILED_TO_UPDATE");
  }
}

export { updateUser };
