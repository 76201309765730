<template>
  <b-overlay :show="isLoading" rounded="sm">
    <validation-observer ref="validationObserverRef" v-slot="{ handleSubmit }">
      <form class="form">
        <div class="card card-custom">
          <div class="card-header border-0 pt-6 pb-0">
            <div class="card-title align-items-start flex-column">
              <h5 class="card-label font-weight-bolder">
                {{ $t("Settings") }}
              </h5>
              <span class="text-muted font-weight-bold font-size-sm mt-1">
                {{ $t("Update your settings") }}
              </span>
            </div>
            <div class="card-toolbar">
              <button :disabled="isLoading" class="btn btn-success mr-2" @click.prevent="handleSubmit(submit)">

                {{ $t("Save Changes") }}
              </button>
            </div>
          </div>
          <div class="card-body detail">
            <b-container fluid>
              <b-container fluid class="mb-5">
                <b-card>
                  <validation-provider v-slot="{ errors }" name="email notifications" vid="communication_email">
                    <euro-checkbox v-model="form.communication_email" size="lg" is-switch :error-messages="errors">
                      <template #text>
                        <p class="h5">{{ $t("Email notifications") }}</p>
                      </template>
                    </euro-checkbox>
                  </validation-provider>

                  <p for="notification-email" class="font-weight-normal">
                    {{ $t("Email") }} : {{ physicalPerson.user_email }}

                  </p>
                </b-card>
              </b-container>
              <b-container class="mb-5">
                <b-card>
                  <validation-provider v-slot="{ errors }" name="sms notifications" vid="communication_sms">
                    <euro-checkbox v-model="form.communication_sms" size="lg" is-switch :error-messages="errors"
                      :disabled="true">
                      <template #text>
                        <p class="h6">{{ $t("SMS notifications") }}</p>
                      </template>
                    </euro-checkbox>
                  </validation-provider>
                  <i for="notification-sms">
                    Coming soon...
                  </i>
                </b-card>
              </b-container>

              <b-container class="mb-5">
                <b-card class="h-100">
                  <validation-provider v-slot="{ errors }" name="software notifications" vid="communication_software">
                    <euro-checkbox v-model="form.communication_software" size="lg" is-switch :disabled="true"
                      :error-messages="errors">
                      <template #text>
                        <p class="h6">{{ $t("Software notifications") }}</p>
                      </template>
                    </euro-checkbox>
                  </validation-provider>
                </b-card>
              </b-container>
            </b-container>

            <div class="separator separator-dashed my-5"></div>
            <b-container>
              <b-row>
                <b-col>
                  <b-card>
                    <h6>{{ $t("Preferred Language") }}</h6>
                    <validation-provider v-slot="{ errors }" rules="required" name="preferred language"
                      vid="preferred_language">
                      <b-form-group :invalid-feedback="errors[0]">
                        <div class="d-flex flex-wrap gap-2 mt-10">
                          <div v-for="language in languages" :key="language.lang">
                            <div class="language d-flex align-center">
                              <b-form-radio v-model="form.preferred_language" size="lg" name="language-radio"
                                :value="language.lang"></b-form-radio>
                              <span class="symbol symbol-20 mr-3">
                                <img :src="language.flag" alt="" />
                              </span>
                              <span class="navi-text">{{ language.name }}</span>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
            <div class="separator separator-dashed my-5"></div>
            <b-container fluid>
              <b-row>
                <b-col cols="6">
                  <b-card class="h-100">
                    <validation-provider v-slot="{ errors }" name="date_format" vid="date format" rules="required">
                      <label for="date-format" class="h6">
                        {{ $t("Preferred date format") }}
                      </label>
                      <euro-select id="date-format" v-model="currentDateFormat" :error-messages="errors" return-object
                        :multiselect-props="dateFormatMultiselectProps"></euro-select>
                    </validation-provider>
                  </b-card>
                </b-col>
                <b-col cols="6">
                  <b-card class="h-100">
                    <validation-provider v-slot="{ errors }" name="timezone" vid="timezone" rules="required">
                      <label for="timezone" class="h6">
                        {{ $t("Preferred timezone") }}
                      </label>
                      <euro-select id="timezone" v-model="form.timezone" :error-messages="errors"
                        :multiselect-props="timezoneMultiselectProps"></euro-select>
                    </validation-provider>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>

          </div>
        </div>
      </form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons.js";
import { mapState, mapGetters, mapMutations } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import DateService from "@/core/services/date.service";
import { updateUser } from "@/core/abstractions/user";
import { backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from '@/core/helpers';
export default {
  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      datesOptions: [
        { text: "YYYY-MM-DD", separator: "-", format: "%Y%m%d" },
        { text: "YYYY/MM/DD", separator: "/", format: "%Y%m%d" },
        { text: "DD-MM-YYYY", separator: "-", format: "%d%m%Y" },
        { text: "DD/MM/YYYY", separator: "/", format: "%d%m%Y" },
      ],
      form: {
        communication_email: false,
        communication_email_target: "",
        communication_sms: false,
        communication_sms_target: "",
        communication_wa: false,
        communication_wa_target: "",
        communication_software: false,
        preferred_language: "",
        timezone: "",
        date_format: "",
        date_separator: "",
      },
      localDateFormat: "",
      languages: i18nService.languages,
      isLoading: false,
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["date_format"]),

    validationObserverRef() {
      return "settings-validation-observer";
    },

    timezones() {
      return DateService.timezones();
    },

    timezoneMultiselectProps() {
      return {
        options: this.timezones.map((el) => ({
          value: el,
          text: el,
        })),
        multiple: false,
        "allow-empty": false,
        searchable: true,
      };
    },

    currentDateFormat: {
      get() {
        return this.localDateFormat;
      },
      set(val) {
        this.localDateFormat = val;
        this.form.date_separator = val.separator ?? "";
        this.form.date_format = val.format ?? "";
      },
    },

    dateFormatMultiselectProps() {
      return {
        options: this.datesOptions,
        label: "text",
        "track-by": "text",
        multiple: false,
        "allow-empty": false,
      };
    },

    phoneMultiselectProps() {
      return {
        options: this.mobilePhones,
        label: "number",
        "track-by": "id",
        multiple: false,
        "allow-empty": true,
        searchable: true,
      };
    },

    emailMultiselectProps() {
      console.log("@emailMultiselectProps", this.physicalPerson.user_email);
      return {
        options: this.physicalPerson.extraemail_set,
        label: "email",
        "track-by": "id",
        multiple: false,
        "allow-empty": true,
        searchable: true,
      };
    },

    mobilePhones() {
      return this.physicalPerson.telcontact_set.filter(tel => tel.kind == "MOB");
    },
  },

  watch: {
    "form.communication_email"(value) {
      if (!value) this.form.communication_email_target = "";
    },
    "form.communication_sms"(value) {
      if (!value) this.form.communication_sms_target = "";
    },
    "form.communication_wa"(value) {
      if (!value) this.form.communication_wa_target = "";
    },
  },

  created() {
    this.form.preferred_language = this.user.preferred_language ?? "en";
    this.form.timezone = this.user.timezone;
    this.form.communication_email = this.user.communication_email;
    this.form.communication_sms = this.user.communication_sms;
    this.form.communication_wa = this.user.communication_wa;
    this.form.communication_software = this.user.communication_software;
    this.form.communication_email_target = this.user.communication_email_target;
    this.form.communication_sms_target = this.user.communication_sms_target;
    this.form.communication_wa_target = this.user.communication_wa_target;

    this.currentDateFormat = {
      text: this.date_format,
      format: this.user.date_format,
      separator: this.user.date_separator,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Settings") },
    ]);
  },

  methods: {
    ...mapMutations("user", ["SET_USER"]),

    async submit() {
      this.isLoading = true;
      try {
        const user = await updateUser(this.user.id, { ...this.form, id: this.user.id });
        this.SET_USER(user);
        successToast(this.$t("Settings updated"));
      } catch (err) {
        if (typeof err == "object") {
          this.$refs[this.validationObserverRef].setErrors(err);
          return;
        }
        backendErrorSwal(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-2 {
  gap: 2rem;
}
</style>
