var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('validation-observer', {
    ref: "validationObserverRef",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h5', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Settings")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("Update your settings")) + " ")])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          attrs: {
            "disabled": _vm.isLoading
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "card-body detail"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-container', {
          staticClass: "mb-5",
          attrs: {
            "fluid": ""
          }
        }, [_c('b-card', [_c('validation-provider', {
          attrs: {
            "name": "email notifications",
            "vid": "communication_email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-checkbox', {
                attrs: {
                  "size": "lg",
                  "is-switch": "",
                  "error-messages": errors
                },
                scopedSlots: _vm._u([{
                  key: "text",
                  fn: function fn() {
                    return [_c('p', {
                      staticClass: "h5"
                    }, [_vm._v(_vm._s(_vm.$t("Email notifications")))])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.form.communication_email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "communication_email", $$v);
                  },
                  expression: "form.communication_email"
                }
              })];
            }
          }], null, true)
        }), _c('p', {
          staticClass: "font-weight-normal",
          attrs: {
            "for": "notification-email"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Email")) + " : " + _vm._s(_vm.physicalPerson.user_email) + " ")])], 1)], 1), _c('b-container', {
          staticClass: "mb-5"
        }, [_c('b-card', [_c('validation-provider', {
          attrs: {
            "name": "sms notifications",
            "vid": "communication_sms"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-checkbox', {
                attrs: {
                  "size": "lg",
                  "is-switch": "",
                  "error-messages": errors,
                  "disabled": true
                },
                scopedSlots: _vm._u([{
                  key: "text",
                  fn: function fn() {
                    return [_c('p', {
                      staticClass: "h6"
                    }, [_vm._v(_vm._s(_vm.$t("SMS notifications")))])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.form.communication_sms,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "communication_sms", $$v);
                  },
                  expression: "form.communication_sms"
                }
              })];
            }
          }], null, true)
        }), _c('i', {
          attrs: {
            "for": "notification-sms"
          }
        }, [_vm._v(" Coming soon... ")])], 1)], 1), _c('b-container', {
          staticClass: "mb-5"
        }, [_c('b-card', {
          staticClass: "h-100"
        }, [_c('validation-provider', {
          attrs: {
            "name": "software notifications",
            "vid": "communication_software"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-checkbox', {
                attrs: {
                  "size": "lg",
                  "is-switch": "",
                  "disabled": true,
                  "error-messages": errors
                },
                scopedSlots: _vm._u([{
                  key: "text",
                  fn: function fn() {
                    return [_c('p', {
                      staticClass: "h6"
                    }, [_vm._v(_vm._s(_vm.$t("Software notifications")))])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.form.communication_software,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "communication_software", $$v);
                  },
                  expression: "form.communication_software"
                }
              })];
            }
          }], null, true)
        })], 1)], 1)], 1), _c('div', {
          staticClass: "separator separator-dashed my-5"
        }), _c('b-container', [_c('b-row', [_c('b-col', [_c('b-card', [_c('h6', [_vm._v(_vm._s(_vm.$t("Preferred Language")))]), _c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "preferred language",
            "vid": "preferred_language"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', {
                attrs: {
                  "invalid-feedback": errors[0]
                }
              }, [_c('div', {
                staticClass: "d-flex flex-wrap gap-2 mt-10"
              }, _vm._l(_vm.languages, function (language) {
                return _c('div', {
                  key: language.lang
                }, [_c('div', {
                  staticClass: "language d-flex align-center"
                }, [_c('b-form-radio', {
                  attrs: {
                    "size": "lg",
                    "name": "language-radio",
                    "value": language.lang
                  },
                  model: {
                    value: _vm.form.preferred_language,
                    callback: function callback($$v) {
                      _vm.$set(_vm.form, "preferred_language", $$v);
                    },
                    expression: "form.preferred_language"
                  }
                }), _c('span', {
                  staticClass: "symbol symbol-20 mr-3"
                }, [_c('img', {
                  attrs: {
                    "src": language.flag,
                    "alt": ""
                  }
                })]), _c('span', {
                  staticClass: "navi-text"
                }, [_vm._v(_vm._s(language.name))])], 1)]);
              }), 0)])];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "separator separator-dashed my-5"
        }), _c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-card', {
          staticClass: "h-100"
        }, [_c('validation-provider', {
          attrs: {
            "name": "date_format",
            "vid": "date format",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('label', {
                staticClass: "h6",
                attrs: {
                  "for": "date-format"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Preferred date format")) + " ")]), _c('euro-select', {
                attrs: {
                  "id": "date-format",
                  "error-messages": errors,
                  "return-object": "",
                  "multiselect-props": _vm.dateFormatMultiselectProps
                },
                model: {
                  value: _vm.currentDateFormat,
                  callback: function callback($$v) {
                    _vm.currentDateFormat = $$v;
                  },
                  expression: "currentDateFormat"
                }
              })];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-card', {
          staticClass: "h-100"
        }, [_c('validation-provider', {
          attrs: {
            "name": "timezone",
            "vid": "timezone",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('label', {
                staticClass: "h6",
                attrs: {
                  "for": "timezone"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Preferred timezone")) + " ")]), _c('euro-select', {
                attrs: {
                  "id": "timezone",
                  "error-messages": errors,
                  "multiselect-props": _vm.timezoneMultiselectProps
                },
                model: {
                  value: _vm.form.timezone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "timezone", $$v);
                  },
                  expression: "form.timezone"
                }
              })];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1)], 1)])])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }